<template>
  <div class="Client font">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="retailer APPROVE"
          :breadcrumb="[
            {
              label: 'Kyc Approve',
              url: 'http://localhost:8080/retailer/client',
            },
            { label: 'Kyc Approve' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card radius"
              style="box-shadow: 0px 0px 10px gray; margin-left: 3px; height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <div class="row">
                    <div class="col">
                      <h4><b style="color: #00364f; font-weight: bolder">Retailer</b></h4>
                    </div>
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll" style="margin-left: -5px">
                      <table class="table table-hover table-sm font text">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-trunacte">+</th>
                          <th class="text-trunacte">Code</th>
                          <th class="text-trunacte">Name</th>
                          <th class="text-trunacte">Email</th>
                          <th class="text-trunacte">Mobile</th>
                          <th class="text-trunacte text-center">Status</th>
                          <th class="text-trunacte text-end">Action</th>
                        </tr>
                        <tbody>
                          <tr v-for="(retailerInfo, index) in retailerkyc" :key="index">
                            <td>
                              <span v-if="retailerInfo.retailer">
                                <img
                                  style="width: 30px; height: 30px; border-radius: 2px"
                                  :src="retailerlogofinder(retailerInfo.retailer)"
                                  alt=""
                                />
                              </span>
                            </td>
                            <td>
                              <span v-if="retailerInfo.retailer">
                                {{ retailerInfo.retailer.code }}
                              </span>
                            </td>
                            <td>
                              <span v-if="retailerInfo.retailer">
                                {{ retailerInfo.retailer.name }}
                              </span>
                            </td>
                            <td>
                              <span v-if="retailerInfo.retailer">
                                {{ retailerInfo.retailer.email }}
                              </span>
                            </td>
                            <td>
                              <span v-if="retailerInfo.retailer">
                                {{ retailerInfo.retailer.phone }}
                              </span>
                            </td>
                            <td class="text-center">
                              <p
                                v-if="retailerInfo.kycStatus == 'approve'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: green;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ retailerInfo.kycStatus }}</b>
                              </p>
                              <p
                                v-if="retailerInfo.kycStatus == 'reject'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ retailerInfo.kycStatus }}</b>
                              </p>
                              <p
                                v-if="retailerInfo.kycStatus == 'cancel'"
                                class="btn btn-sm"
                                style="
                                  width: 100px;
                                  background-color: red;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b>{{ retailerInfo.kycStatus }}</b>
                              </p>
                            </td>
                            <td class="text-end">
                              <button
                                @click="valueAssign(retailerInfo)"
                                style="
                                  background-color: #1426f5;
                                  padding: 5px 4.5px 5px 4.5px;
                                "
                                class="btn btn-sm text-white"
                                data-bs-toggle="modal"
                                data-bs-target="#retailer"
                              >
                                <font-awesome-icon icon="tag" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="retailer"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <b>Approve Retailer Kyc </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th style="width: 10%">&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                <th style="width: 80%">Ducument Name</th>
                <th class="text-end" style="width: 10%">Download</th>
              </tr>

              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Aadhaar Front&nbsp;&nbsp;</b>
                    <span>{{ this.aadharNoForKyc }}</span>
                    <select
                      @change="aadharKycApprovedSave()"
                      class="form-select"
                      v-model="aadharapproveKyc.status"
                      style="height: 28px; padding: 5px 5px 5px 5px"
                    >
                      <!-- <option selected disabled>Choose status...</option> -->

                      <option value="approve">Approve</option>
                      <!-- <option value="reject">Reject</option> -->
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <!-- <label for="AdharFront"> -->
                    <button
                      @click="
                        extensionkycAdharFront == 'pdf'
                          ? openWindow(adharFrontImgPdf)
                          : openWindow(adharFrontImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Aadhaar Back</b>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycAdharBack == 'pdf'
                          ? openWindow(adharBackImgPdf)
                          : openWindow(adharBackImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Retailer Pan &nbsp;&nbsp;</b>
                    <span> {{ this.panNoForKyc }}</span>
                    <select
                      v-model="panapproveKyc.status"
                      @change="panKycApprovedSave()"
                      class="form-select"
                      style="height: 28px; padding: 5px 5px 5px 5px"
                    >
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycPanPhoto == 'pdf'
                          ? openWindow(panImgPdf)
                          : openWindow(panImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <!-- <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" /> -->
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Retailer Kyc</b>
                    <select
                      v-model="approveKyc.status"
                      class="form-select"
                      style="height: 28px; padding: 5px 5px 5px 5px"
                    >
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                    <br />
                    <textarea
                      v-model="approveKyc.remark"
                      rows="2"
                      class="form-control"
                      placeholder="Remark"
                    ></textarea>
                  </td>

                  <td class="text-truncate text-end">
                    <button
                      type="button"
                      @click="retailerKycApprovedSave"
                      class="btn btns text-white btn-sm"
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    >
                      <font-awesome-icon icon="floppy-disk" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <!-- <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button> -->
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "retailerKycApprove",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      loading: false,
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      aadharNoForKyc: "",
      accountNoForKyc: "",
      panNoForKyc: "",
      retailerkyc: [],
      approveKyc: new Form({
        retailer_id: null,
        type: "kyc",
        status: "cancel",
        remark: "",
      }),

      aadharapproveKyc: new Form({
        retailer_id: null,
        type: "aadhar",
        status: "cancel",
        remark: "Aadhar Kyc",
      }),
      panapproveKyc: new Form({
        retailer_id: null,
        type: "pan",
        status: "cancel",
        remark: "",
      }),
      accountapproveKyc: new Form({
        retailer_id: null,
        type: "account",
        status: "cancel",
        remark: "",
      }),
      assign_form: {
        client_service_id: null,
        retailer_id: null,
      },
    };
  },
  methods: {
    openWindow(url) {
      window.open(`${url}`);
    },
    aadharKycApprovedSave() {
      this.$axios
        .post("staff/approveretailerkyc", this.aadharapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Aaddhar Kyc " + this.aadharapproveKyc.status + " Succesfully ", {
            autoClose: 1000,
          });

          this.loadretailerKyc();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    accountApproveKycSave() {
      this.$axios
        .post("staff/approveretailerkyc", this.accountapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success(
            "Account Kyc " + this.accountapproveKyc.status + " Succesfully  ",
            {
              autoClose: 1000,
            }
          );

          this.loadretailerKyc();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    panKycApprovedSave() {
      this.$axios
        .post("staff/approveretailerkyc", this.panapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Pan Kyc " + this.panapproveKyc.status + " Succesfully  ", {
            autoClose: 1000,
          });

          this.loadretailerKyc();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    retailerKycApprovedSave() {
      this.$axios
        .post("staff/approveretailerkyc", this.approveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Retailer Kyc " + this.approveKyc.status + " Succesfully", {
            autoClose: 1000,
          });

          this.loadretailerKyc();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    valueAssign(kyc) {
      this.accountapproveKyc.retailer_id = kyc.retailer.id;
      this.panapproveKyc.retailer_id = kyc.retailer.id;
      this.aadharapproveKyc.retailer_id = kyc.retailer.id;
      this.approveKyc.retailer_id = kyc.retailer.id;
      this.aadharNoForKyc = kyc.aadharNo;
      this.accountNoForKyc = kyc.accountNo;
      this.panNoForKyc = kyc.panNo;

      if (kyc.panPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          kyc.retailer_id +
          "/" +
          kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            kyc.retailer_id +
            "/" +
            kyc.panPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          kyc.retailer_id +
          "/thumbs/" +
          kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            kyc.retailer_id +
            "/thumbs/" +
            kyc.panPhoto;
        }

        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (kyc.aadharFrontPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          kyc.retailer_id +
          "/" +
          kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            kyc.retailer_id +
            "/" +
            kyc.aadharFrontPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          kyc.retailer_id +
          "/thumbs/" +
          kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            kyc.retailer_id +
            "/thumbs/" +
            kyc.aadharFrontPhoto;
        }

        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (this.extensionkycAdharFront == "pdf") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (kyc.aadharBackPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          kyc.retailer_id +
          "/" +
          kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            kyc.retailer_id +
            "/" +
            kyc.aadharBackPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/retailer/" +
          kyc.retailer_id +
          "/thumbs/" +
          kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/retailer/" +
            kyc.retailer_id +
            "/thumbs/" +
            kyc.aadharBackPhoto;
        }

        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }

      //  if (kyc.passbookPhoto != null) {
      //                  this.passBookImg = this.$store.state.imgUrl + '/retailer/' +  kyc.retailer_id + '/thumbs/' + kyc.passbookPhoto
      //             } else {
      //                 this.passBookImg = this.$store.state.placeholderImg
      //             }
    },
    retailerlogofinder(retailer) {
      var img;
      if (retailer.photo != null) {
        img =
          this.$store.state.imgUrl +
          "/retailer/" +
          retailer.id +
          "/thumbs/" +
          retailer.photo;
        return img;
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    loadretailerKyc() {
      this.loading = true;
      this.$axios
        .get(`staff/retailerkyc`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.retailerkyc = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadretailerKyc();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  width: 95%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray;
  line-height: 10%;
  margin-left: 13px;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #fefeff;
  border-color: #f21003;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

th {
  width: 200px;
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}
</style>
